import { createBreakpoints } from '@mui/system';

export const baseThemeProps = {
  borderRadius: {
    /* eslint-disable perfectionist/sort-objects */
    xs: '0',
    sm: '10px',
    md: '15px',
    lg: '20px',
    xl: '50px',
    cylinder: '100px',
    /* eslint-enable perfectionist/sort-objects */
  },
  breakpoints: createBreakpoints({
    values: {
      /* eslint-disable perfectionist/sort-objects */
      xs: 0,
      sm: 601,
      md: 961,
      lg: 1281,
      xl: 1921,
      /* eslint-enable perfectionist/sort-objects */
    },
  }),
  shape: {
    borderRadius: 15,
  },
  spacingForNavbar: '0px',
  themeName: 'Website',
};
