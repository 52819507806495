/* eslint-disable react/no-multi-comp */

import { Fragment } from 'react';
import { Typography } from '@mui/material';
import type { FormProps, IChangeEvent } from '@rjsf/core';
import { withTheme } from '@rjsf/core';
import { Theme as MuiTheme } from '@rjsf/mui';
import type { ObjectFieldTemplateProps, RJSFSchema, TitleFieldProps } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { formatErrors, generateUiSchema, transformErrors } from '../utils/dynamicForm';
import { FormGrid } from './FormGrid';
import { FormItem } from './FormItem';

export type TpRJSFSchema = RJSFSchema;

type FormData = Record<string, unknown>;

type DynamicFormProps = Omit<FormProps, 'onSubmit' | 'validator'> & {
  fieldMappings?: Record<string, string>;
  isLoading: boolean;
  onSubmit: (state: { formData: FormData }) => void;
  validationErrors?: Record<string, unknown> | null;
};

function TitleFieldTemplate({ title }: TitleFieldProps): React.ReactElement {
  return <Typography variant="paragraphBodyLBold">{title}</Typography>;
}

function ObjectFieldTemplate({ properties, title }: ObjectFieldTemplateProps): React.ReactElement {
  return (
    <Fragment>
      {title && <Typography variant="paragraphBodyLBold">{title}</Typography>}
      <FormGrid sx={{ pb: 1, pt: 2 }}>
        {properties.map((prop) => (
          <FormItem key={prop.name} sx={{ pt: 1 }}>
            {prop.content}
          </FormItem>
        ))}
      </FormGrid>
    </Fragment>
  );
}
const Form = withTheme(MuiTheme);

export function DynamicForm({
  fieldMappings,
  formData,
  isLoading,
  onSubmit,
  schema,
  validationErrors,
  ...rest
}: DynamicFormProps): React.ReactElement {
  const generatedUiSchema = generateUiSchema(schema);
  const extraErrors = formatErrors(validationErrors, schema, fieldMappings);

  return (
    <Form
      css={{ width: '100%' }}
      extraErrors={extraErrors}
      formData={formData}
      schema={schema}
      showErrorList={false}
      templates={{ ObjectFieldTemplate, TitleFieldTemplate }}
      transformErrors={transformErrors}
      uiSchema={generatedUiSchema}
      validator={validator}
      onSubmit={(data: IChangeEvent): void => {
        if (!data.formData) {
          return;
        }
        onSubmit({ formData: data.formData });
      }}
      {...rest}
    >
      <PrimaryButton loading={isLoading} type="submit">
        Continue
      </PrimaryButton>
    </Form>
  );
}
